@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);
html,
body {
    overflow-x: hidden;
}
body {
    background: #fafafa;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    box-sizing: border-box;
}
hr {
    background-color: #dcdcdc;
    outline: none;
    border: 0 none;
    height: 1px;
    margin: 35px -35px 10px -35px;
}

hr.bold {
    background-color: #dcdcdc;
    outline: none;
    border: 0 none;
    height: 2px;
    margin: 35px -35px 10px -35px;
}

@media only screen and (max-width: 767px) {
    hr {
        margin: 25px -15px 10px -15px;
    }
    table td:first-child {
        padding-top: 15px !important;
    }
    table td:nth-last-child(2) {
        padding-bottom: 15px !important;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        width: 100%;
        padding: 15px;
        margin: 0;
    }
}

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
}

.row.align-center {
    align-items: center;
}

.row.reverse {
    flex-direction: row-reverse;
}

.col.reverse {
    flex-direction: column-reverse;
}

.col-end-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

.col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-xs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-xs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-xs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-xs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-xs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-xs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-xs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.col-xs-offset-0 {
    margin-left: 0;
}

.col-xs-offset-1 {
    margin-left: 8.33333333%;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.33333333%;
}

.col-xs-offset-5 {
    margin-left: 41.66666667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.33333333%;
}

.col-xs-offset-8 {
    margin-left: 66.66666667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.33333333%;
}

.col-xs-offset-11 {
    margin-left: 91.66666667%;
}

.start-xs {
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    justify-content: center;
    text-align: center;
}

.end-xs {
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    align-items: flex-start;
}

.middle-xs {
    align-items: center;
}

.bottom-xs {
    align-items: flex-end;
}

.around-xs {
    justify-content: space-around;
}

.between-xs {
    justify-content: space-between;
}

.first-xs {
    order: -1;
}

.last-xs {
    order: 1;
}

@media only screen and (min-width: 48em) {
    .container {
        width: 100%;
        padding: 24px 64px 24px 64px;
        margin: 0;
    }

    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-offset-0,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-sm {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-sm-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-sm-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-sm-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-sm-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-sm-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-sm-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-sm-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-sm {
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        align-items: flex-start;
    }

    .middle-sm {
        align-items: center;
    }

    .bottom-sm {
        align-items: flex-end;
    }

    .around-sm {
        justify-content: space-around;
    }

    .between-sm {
        justify-content: space-between;
    }

    .first-sm {
        order: -1;
    }

    .last-sm {
        order: 1;
    }
}

@media only screen and (min-width: 64em) {
    .container {
        width: 100%;
        padding: 32px 64px 32px 64px;
        margin: 0;
    }

    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-offset-0,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-md-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-md-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-md-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-md-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-md-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-md-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-md-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-md-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-md {
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        justify-content: center;
        text-align: center;
    }

    .end-md {
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        align-items: flex-start;
    }

    .middle-md {
        align-items: center;
    }

    .bottom-md {
        align-items: flex-end;
    }

    .around-md {
        justify-content: space-around;
    }

    .between-md {
        justify-content: space-between;
    }

    .first-md {
        order: -1;
    }

    .last-md {
        order: 1;
    }
}

@media only screen and (min-width: 75em) {
    .container {
        width: 100%;
        padding: 32px 64px 32px 64px;
        margin: 0;
    }

    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-offset-0,
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-lg-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-lg-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-lg-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-lg-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-lg-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-lg-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-lg-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-lg-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-lg {
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        align-items: flex-start;
    }

    .middle-lg {
        align-items: center;
    }

    .bottom-lg {
        align-items: flex-end;
    }

    .around-lg {
        justify-content: space-around;
    }

    .between-lg {
        justify-content: space-between;
    }

    .first-lg {
        order: -1;
    }

    .last-lg {
        order: 1;
    }
}

.entryText {
    color: #000;
    font-size: 14px;
    line-height: 1.42;
}

.entryText p {
    margin: 0;
    padding: 0;
}

.entryText p + p {
    margin: 24px 0 0;
}

.entryText > *:first-child {
    margin-top: 0 !important;
}

.entryText hr {
    margin: 32px auto;
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 1px;
    outline: 0 none;
    border: 0 none;
}

.entryText img {
    display: block;
    max-width: 100%;
    margin: 24px auto;
}

.entryText h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h3,
.entryText h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText ul,
.entryText ol {
    margin: 24px 0;
}

.entryText ul li,
.entryText ol li {
    margin-top: 0;
    margin-bottom: 0;
}

.entryText ul li + li,
.entryText ol li + li {
    margin-top: 12px;
}

.entryText blockquote {
    margin-left: 0;
    color: rgba(0, 0, 0, 0.54);
    font-style: italic;
    border-left: 3px solid rgba(0, 0, 0, 0.1);
}

.entryText .fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
}

.entryText .fr-emoticon {
    font-weight: 400;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol",
        "Android Emoji", EmojiSymbols;
    display: inline;
    line-height: 0;
}

.entryText .fr-img-wrap img {
    margin: 0;
}

.entryText .fr-img-wrap img + span {
    display: block;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
    margin: 8px 0 0;
}

.entryText .fr-video {
    position: relative;
    padding-bottom: 56.25%;
    /* padding-top: 30px; */
    /* height: auto !important; */
    margin: 24px auto;
    overflow: hidden;
    display: block;
}

.entryText .fr-video iframe,
.entryText .fr-video object,
.entryText .fr-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.entryText table {
    margin: 24px 0;
    border-collapse: collapse;
}

.entryText table th,
.entryText table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
}

@media only screen and (max-width: 767px) {
    .entryText table th,
    .entryText table td {
        padding: 8px;
    }
}

.entryText table th {
    font-weight: bold;
}

.howSync p {
    margin: 0;
    padding: 0;
}

.howSync p + p {
    margin: 24px 0 0;
}

.howSync > *:first-child {
    margin-top: 0 !important;
}

.howSync hr {
    margin: 32px auto;
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 1px;
    outline: 0 none;
    border: 0 none;
}

.howSync img {
    display: block;
    max-width: 100%;
    margin: 24px auto;
}

.howSync .dataBlock {
    display: block;
    margin: 24px 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    padding: 24px;
    word-break: break-all;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 9000099;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

/* TEMPORARY SOLUTION FOR TABLE */
.eventItem {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.eventItemDate {
    display: block;
}
.calendarItem {
    display: block;
}
.calendarSourceItem {
    display: block;
    font-size: 10px;
    opacity: 0.7;
    margin-top: 4px;
}
.audienceItem {
    display: block;
}

@media only screen and (max-width: 767px) {
    .eventItem {
        font-weight: 500;
        text-overflow: unset;
        overflow: visible;
        white-space: normal;
    }

    .calendarItem {
        display: inline;
    }

    .calendarSourceItem {
        display: inline;
    }
}

.rbc-calendar-container {
    height: 100vh;
}
@media only screen and (max-width: 767px) {
    .rbc-calendar-container {
        height: auto;
    }
}
.rbc-btn {
    color: inherit;
    font: inherit;
    margin: 0;
}
button.rbc-btn {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled].rbc-btn {
    cursor: not-allowed;
}
button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    box-sizing: inherit;
}
.rbc-abs-full,
.rbc-row-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.rbc-rtl {
    direction: rtl;
}
.rbc-off-range {
    color: #999999;
    opacity: 0.5;
}
.rbc-off-range-bg {
    background: transparent;
}
.rbc-header {
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #e0e0e0;
}
.rbc-header + .rbc-header {
    border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid #e0e0e0;
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
    color: inherit;
    text-decoration: none;
}
.rbc-row-content {
    position: relative;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    z-index: 4;
}
.rbc-today {
    background-color: rgba(0, 0, 0, 0.03);
}
.rbc-toolbar {
    padding: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font-size: 16px;
}
@media only screen and (max-width: 767px) {
    .rbc-toolbar {
        padding: 10px 15px;
        flex-direction: column-reverse;
    }
}
.rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center;
}
.rbc-toolbar button {
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: transparent;
    background-image: none;
    border: 1px solid #e0e0e0;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    outline: 0 none;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
    background-image: none;
    box-shadow: none;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus {
    color: #000;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}
.rbc-toolbar button:focus {
    color: #000;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}
.rbc-toolbar button:hover {
    color: #000;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}
.rbc-btn-group {
    display: inline-block;
    white-space: nowrap;
}
@media only screen and (max-width: 767px) {
    .rbc-btn-group:last-of-type {
        display: none;
    }
    .rbc-btn-group {
        margin-top: 10px;
    }
}
.rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.rbc-btn-group button + button {
    margin-left: -1px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
    margin-left: 10px;
}
.rbc-event {
    padding: 5px 7px;
    background-color: #e2e2e2;
    border-radius: 5px;
    color: #000;
    cursor: pointer;

    font-size: 12px;
    font-weight: 500;
}
.rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
}
.rbc-event.rbc-selected {
    background-color: #bbb;
}
.rbc-event-label {
    font-size: 80%;
    display: none;
}
.rbc-event-overlaps {
    box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.rbc-event-continues-prior {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-earlier {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rbc-event-continues-later {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-day-after {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.rbc-event-continues-day-prior {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.rbc-row {
    display: flex;
    flex-direction: row;
}
.rbc-row-segment {
    padding: 0 1px 1px 1px;
}
.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.1);
}
.rbc-show-more {
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 4;
    font-weight: bold;
    font-size: 85%;
    height: auto;
    line-height: normal;
    white-space: nowrap;
}
.rbc-month-view {
    position: relative;
    border: 1px solid #e0e0e0;
    border-right: 0 none;
    border-left: 0 none;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    width: 100%;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
}
.rbc-month-header {
    display: flex;
    flex-direction: row;
}
.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0;
    flex-basis: 0px;
    overflow: hidden;
    height: 100%;
}
.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #e0e0e0;
}
.rbc-date-cell {
    flex: 1 1;
    min-width: 0;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    text-align: right;
}
.rbc-date-cell.rbc-now {
    font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
}
.rbc-row-bg {
    display: flex;
    flex-direction: row;
    flex: 1 0;
    overflow: hidden;
}
.rbc-day-bg {
    flex: 1 0;
}
.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid #e0e0e0;
}
.rbc-overlay {
    position: absolute;
    z-index: 5;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    padding: 10px;
}
.rbc-overlay > * + * {
    margin-top: 1px;
}
.rbc-overlay-header {
    border-bottom: 1px solid #e5e5e5;
    margin: -10px -10px 5px -10px;
    padding: 2px 10px;
}
.rbc-agenda-view {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: auto;
}
.rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-right: 0 none;
    border-left: 0 none;
    border-spacing: 0;
    border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 10px 5px !important;
    font-size: 12px;
    vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td:first-child {
    padding: 10px 5px;
}
@media only screen and (max-width: 767px) {
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td:first-child {
        padding: 10px 5px !important;
        /* padding-top: 5px; */
    }
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid #e0e0e0;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid #e0e0e0;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    text-align: right;
}
.rbc-agenda-time-cell {
    text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
    content: " »";
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
    content: "« ";
}
.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
    white-space: nowrap;
}
.rbc-agenda-event-cell {
    width: 100%;
}
.rbc-time-column {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
    flex: 1 1;
}
.rbc-timeslot-group {
    border-bottom: 1px solid #e0e0e0;
    min-height: 50px;
    display: flex;
    flex-flow: column nowrap;
}
.rbc-time-gutter,
.rbc-header-gutter {
    flex: none;
}
.rbc-label {
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
}
.rbc-day-slot {
    position: relative;
}
.rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 10px;
    top: 0;
}
.rbc-day-slot .rbc-events-container.rbc-is-rtl {
    left: 10px;
    right: 0;
}
.rbc-day-slot .rbc-event {
    border: 1px solid #bbb;
    display: flex;
    max-height: 100%;
    min-height: 23px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}
.rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto;
}
.rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
    border-top: 1px solid #f7f7f7;
}
.rbc-time-slot {
    flex: 1 0;
}
.rbc-time-slot.rbc-now {
    font-weight: bold;
}
.rbc-day-header {
    text-align: center;
}
.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 75%;
    width: 100%;
    padding: 3px;
}
.rbc-slot-selecting {
    cursor: move;
}
.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-right: 0 none;
    border-left: 0 none;
    min-height: 0;
}
.rbc-time-view .rbc-time-gutter {
    white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    position: relative;
}
.rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4;
}
.rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
}
.rbc-time-header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
    border-right: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid #e0e0e0;
}
.rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid #e0e0e0;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid #e0e0e0;
}
.rbc-time-header-content {
    flex: 1 1;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-time-header-content {
    border-left-width: 0;
    border-right: 1px solid #e0e0e0;
}
.rbc-time-content {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #ddd;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}
.rbc-time-content > .rbc-time-gutter {
    flex: none;
}
.rbc-time-content > * + * > * {
    border-left: 1px solid #e0e0e0;
}
.rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid #e0e0e0;
}
.rbc-time-content > .rbc-day-slot {
    width: 100%;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}
.rbc-current-time-indicator {
    position: absolute;
    z-index: 3;
    height: 1px;
    background-color: rgb(244, 49, 49);
    pointer-events: none;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button {
    cursor: pointer;
}

a {
    color: #23a493;
    text-decoration: none;
}

img {
    max-width: 100%;
}

figure {
    margin: 30px 0;
}

figure figcaption {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.15;
    color: "rgba(0, 0, 0, 0.87)";
    display: block;
    margin: 4px 0 0;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}

:-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}

input,
select,
textarea {
    border: 0 none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 11px 16px;
    line-height: inherit;
    outline: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
    width: auto;
}

textarea {
    margin: 0;
    resize: none;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

.ReactModal__Content {
    opacity: 0;
}

.ReactModal__Content--after-open {
    opacity: 1;
    transition: opacity 150ms;
}

.ReactModal__Content--before-close {
    opacity: 0;
}

