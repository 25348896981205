@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900");

html,
body {
    overflow-x: hidden;
}
body {
    background: #fafafa;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
* {
    box-sizing: border-box;
}
hr {
    background-color: #dcdcdc;
    outline: none;
    border: 0 none;
    height: 1px;
    margin: 35px -35px 10px -35px;
}

hr.bold {
    background-color: #dcdcdc;
    outline: none;
    border: 0 none;
    height: 2px;
    margin: 35px -35px 10px -35px;
}

@media only screen and (max-width: 767px) {
    hr {
        margin: 25px -15px 10px -15px;
    }
    table td:first-child {
        padding-top: 15px !important;
    }
    table td:nth-last-child(2) {
        padding-bottom: 15px !important;
    }
}

@media only screen and (max-width: 767px) {
    .container {
        width: 100%;
        padding: 15px;
        margin: 0;
    }
}

.row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
}

.row.align-center {
    align-items: center;
}

.row.reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.col.reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.col-end-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

.col-xs {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
}

.col-xs-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-xs-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-xs-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
}

.col-xs-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-xs-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-xs-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
}

.col-xs-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-xs-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-xs-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-xs-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-xs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}

.col-xs-offset-0 {
    margin-left: 0;
}

.col-xs-offset-1 {
    margin-left: 8.33333333%;
}

.col-xs-offset-2 {
    margin-left: 16.66666667%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.33333333%;
}

.col-xs-offset-5 {
    margin-left: 41.66666667%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.33333333%;
}

.col-xs-offset-8 {
    margin-left: 66.66666667%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.33333333%;
}

.col-xs-offset-11 {
    margin-left: 91.66666667%;
}

.start-xs {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.end-xs {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.middle-xs {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottom-xs {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.around-xs {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.between-xs {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.first-xs {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.last-xs {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

@media only screen and (min-width: 48em) {
    .container {
        width: 100%;
        padding: 24px 64px 24px 64px;
        margin: 0;
    }

    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-offset-0,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12 {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-sm {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-sm-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-sm-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-sm-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-sm-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-sm-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-sm-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-sm-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-sm-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-sm-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-sm-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-sm-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-sm {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .middle-sm {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .bottom-sm {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .around-sm {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-sm {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .first-sm {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .last-sm {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media only screen and (min-width: 64em) {
    .container {
        width: 100%;
        padding: 32px 64px 32px 64px;
        margin: 0;
    }

    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-offset-0,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12 {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-md {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-md-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-md-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-md-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-md-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-md-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-md-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-md-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-md-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-md {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-md {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .middle-md {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .bottom-md {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .around-md {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-md {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .first-md {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .last-md {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media only screen and (min-width: 75em) {
    .container {
        width: 100%;
        padding: 32px 64px 32px 64px;
        margin: 0;
    }

    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-offset-0,
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12 {
        box-sizing: border-box;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-lg-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-lg-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-lg-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-lg-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-lg-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-lg-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-lg-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-lg-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.66666667%;
    }

    .start-lg {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .middle-lg {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .bottom-lg {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .around-lg {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .between-lg {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .first-lg {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .last-lg {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

.entryText {
    color: #000;
    font-size: 14px;
    line-height: 1.42;
}

.entryText p {
    margin: 0;
    padding: 0;
}

.entryText p + p {
    margin: 24px 0 0;
}

.entryText > *:first-child {
    margin-top: 0 !important;
}

.entryText hr {
    margin: 32px auto;
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 1px;
    outline: 0 none;
    border: 0 none;
}

.entryText img {
    display: block;
    max-width: 100%;
    margin: 24px auto;
}

.entryText h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText h3,
.entryText h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.16;
    margin: 24px 0;
}

.entryText ul,
.entryText ol {
    margin: 24px 0;
}

.entryText ul li,
.entryText ol li {
    margin-top: 0;
    margin-bottom: 0;
}

.entryText ul li + li,
.entryText ol li + li {
    margin-top: 12px;
}

.entryText blockquote {
    margin-left: 0;
    color: rgba(0, 0, 0, 0.54);
    font-style: italic;
    border-left: 3px solid rgba(0, 0, 0, 0.1);
}

.entryText .fr-emoticon.fr-emoticon-img {
    background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -0.1em 0.1em 0.1em;
    line-height: 1;
    vertical-align: middle;
}

.entryText .fr-emoticon {
    font-weight: 400;
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol",
        "Android Emoji", EmojiSymbols;
    display: inline;
    line-height: 0;
}

.entryText .fr-img-wrap img {
    margin: 0;
}

.entryText .fr-img-wrap img + span {
    display: block;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.42);
    margin: 8px 0 0;
}

.entryText .fr-video {
    position: relative;
    padding-bottom: 56.25%;
    /* padding-top: 30px; */
    /* height: auto !important; */
    margin: 24px auto;
    overflow: hidden;
    display: block;
}

.entryText .fr-video iframe,
.entryText .fr-video object,
.entryText .fr-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.entryText table {
    margin: 24px 0;
    border-collapse: collapse;
}

.entryText table th,
.entryText table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 24px;
}

@media only screen and (max-width: 767px) {
    .entryText table th,
    .entryText table td {
        padding: 8px;
    }
}

.entryText table th {
    font-weight: bold;
}

.howSync p {
    margin: 0;
    padding: 0;
}

.howSync p + p {
    margin: 24px 0 0;
}

.howSync > *:first-child {
    margin-top: 0 !important;
}

.howSync hr {
    margin: 32px auto;
    background-color: rgba(0, 0, 0, 0.1);
    width: 50%;
    height: 1px;
    outline: 0 none;
    border: 0 none;
}

.howSync img {
    display: block;
    max-width: 100%;
    margin: 24px auto;
}

.howSync .dataBlock {
    display: block;
    margin: 24px 0;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    padding: 24px;
    word-break: break-all;
}

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 9000099;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

/* TEMPORARY SOLUTION FOR TABLE */
.eventItem {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.eventItemDate {
    display: block;
}
.calendarItem {
    display: block;
}
.calendarSourceItem {
    display: block;
    font-size: 10px;
    opacity: 0.7;
    margin-top: 4px;
}
.audienceItem {
    display: block;
}

@media only screen and (max-width: 767px) {
    .eventItem {
        font-weight: 500;
        text-overflow: unset;
        overflow: visible;
        white-space: normal;
    }

    .calendarItem {
        display: inline;
    }

    .calendarSourceItem {
        display: inline;
    }
}
