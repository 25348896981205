@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900");

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
button {
    cursor: pointer;
}

a {
    color: #23a493;
    text-decoration: none;
}

img {
    max-width: 100%;
}

figure {
    margin: 30px 0;
}

figure figcaption {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.15;
    color: "rgba(0, 0, 0, 0.87)";
    display: block;
    margin: 4px 0 0;
}

::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}

:-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
}

input,
select,
textarea {
    border: 0 none;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    margin: 0;
    padding: 11px 16px;
    line-height: inherit;
    outline: none;
    appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
    width: auto;
}

textarea {
    margin: 0;
    resize: none;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

.ReactModal__Content {
    opacity: 0;
}

.ReactModal__Content--after-open {
    opacity: 1;
    transition: opacity 150ms;
}

.ReactModal__Content--before-close {
    opacity: 0;
}
